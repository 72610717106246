import React from "react";
import SEO from "src/components/seo.js";

const Index = () => {
  return (
    <>
      <SEO title="HXOUSE" />
    </>
  );
};

export default Index;
